<template>
    <div>
        <template>
            <v-row justify="center">
                <v-dialog v-model="modal"
                          persistent
                          max-width="600px">
                    <v-card>
                        <div class="float-right mr-5 mt-3 px-2 py-1 rounded-circle"
                             @click="closeDialog"
                             style="background-color:#FAFAFA;cursor: pointer;">
                            <v-icon color="#00B2A9"
                                    size="15"
                                    class="mt-n1 mx-auto">mdi-close-circle-outline</v-icon>
                        </div>
                        <v-card-title>
                            <div class="color mt-2 text-green">
                                {{ $t('Packages Details') }}
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <v-row dense
                                   class="">
                                <v-col cols="12"
                                       md="6"
                                       sm="6">
                                    <v-text-field dense
                                                  :label="$t('Package Name')"
                                                  :disabled="true"
                                                  :placeholder="$t('Enter Package Name')"
                                                  v-model="packageName"></v-text-field>
                                </v-col>
                                <v-col cols="12"
                                       md="6"
                                       sm="6">
                                    <v-text-field hide-details="auto"
                                                  dense
                                                  :disabled="true"
                                                  :label="$t('Package Price (SAR)')"
                                                  :placeholder="$t('Enter Price in SAR')"
                                                  v-model="packagePrice"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <p class="font-weight-black text-body-2 text-black">
                                        {{ $t('Package Description') }}
                                    </p>
                                    <v-textarea rows="3"
                                                hide-details="auto"
                                                outlined
                                                auto-grow
                                                :disabled="true"
                                                autocomplete="Category Description"
                                                :placeholder="$t('Enter Package Description')"
                                                v-model="packageDescription">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12">
                                    <p class="font-weight-black text-body-2 text-black">
                                        {{ $t('Upload Document') }}
                                    </p>
                                    <div class="upload-docu mr-3"
                                         style="padding:0px !important"
                                         tile>
                                        <input type="file"
                                               name="file"
                                               id="packageDocument"
                                               class="hidden-input"
                                               ref="filePackage"
                                               hidden
                                               accept=".pdf,.jpg,.jpeg,.png" />
                                        <v-img v-if="packageImage"
                                               :src="packageImage"
                                               width="100%"
                                               style="border-radius: 10px;height: 100%;max-height: 154px;"
                                               class="me-2"></v-img>

                                        <label v-if="!packageImage"
                                               for="fileInput"
                                               class="file-label">
                                            <img class="imge"
                                                 :src="require(`@/assets/images/dummy-img.png`)"
                                                 alt="">
                                        </label>

                                    </div>
                                </v-col>

                                <v-row class="mx-0">
                                    <v-col cols="12">

                                        <p class="font-weight-black text-body-2 text-black mt-3">
                                            {{ $t('Package Items') }}
                                        </p>
                                        <div class="package-item mt-1"
                                             v-if="this.packageItems.length > 0">
                                            <v-chip small
                                                    v-for="( field, index ) in  packageItems"
                                                    :key="index"
                                                    :disabled="true"
                                                    label
                                                    class="mr-3 mt-1"
                                                    style="background-color: #F3F3F3; color: black">
                                                {{ field }} &nbsp <span @click=remove(index)
                                                      class="cross">x</span>
                                            </v-chip>
                                        </div>

                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>

<script>
import config from '/config';
export default {
    props: {
        modal: {
            default: false,
            type: Boolean
        },
        packageInfos: {
            default()
            {
                return {}
            },
            type: Object
        },
    },
    data()
    {
        return {
            packageName: '',
            packagePrice: '',
            packageDescription: '',
            packageImage: '',
            packageItems: []
        }
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        packageInfos: {
            immediate: true,
            handler(newVal)
            {
                this.packageItems = [];
                this.localObject = Object.assign({}, newVal);
                this.packageName = this.localObject.name
                this.packagePrice = this.localObject.price
                this.packageDescription = this.localObject.description
                this.packageImage = config.MYBOAT_IMG_URL + this.localObject.image
                if (this.localObject.tags)
                {
                    let item = this.localObject.tags.split(",");
                    if (item.length)
                    {
                        item.forEach(element =>
                        {
                            this.packageItems.push(element);
                        });
                    }
                    else
                    {
                        this.packageItems = this.localObject.tags;
                    }
                }


            }
        }
    },
    methods: {
        closeDialog()
        {
            this.$emit('closed');
            return this.modal = !this.modal;
        }
    }
}
</script>

<style scoped>
.color {
    color: #00B2A9;
}

.upload-docu {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 38px 0px;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

/* .package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding: 30px;
} */
</style>