<template>
    <div>
        <v-card class="pa-3 mt-4">
            <v-card-text class="d-flex align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <div class="mx-2 d-flex align-center">
                        <span class="font-weight-semibold text-base text-green">
                            {{ $t('Boat Pictures') }}
                        </span>
                    </div>
                </div>

                <v-spacer></v-spacer>


            </v-card-text>

            <v-card-text>
                <v-row class="mx-2 py-3">
                    <p class="text-black">
                        {{ $t('Upload Pictures') }}
                    </p>
                    <v-col sm="6"
                           md="4"
                           lg="12">

                        <v-row class="mr-3">
                            <div v-for="( field, index ) in  boatPictures"
                                 :key="index"
                                 class="upload-document mt-3 mr-3"
                                 style="padding:0px !important"
                                 tile>
                                <input type="file"
                                       name="file"
                                       id="boatGallery"
                                       class="hidden-input"
                                       ref="file"
                                       @change="uploadPicture"
                                       hidden
                                       accept=".jpg,.jpeg,.png" />
                                <v-img v-if="field.image"
                                       :src="field.image"
                                       width="100%"
                                       style="border-radius: 10px;height: 100%;max-height: 154px;"
                                       class="me-2"></v-img>


                                <span v-if="field.image && !isView"
                                      class="category-icon"
                                      @click="deleteBoatPicture(field, index)">
                                    <v-img :src="require(`@/assets/images/circle-cross.png`)"
                                           height="21px"
                                           width="22px"></v-img>
                                </span>
                                <label for="fileInput"
                                       class="file-label">
                                    <img v-if="!field.image"
                                         class="imge"
                                         :src="require(`@/assets/images/dummy-img.png`)"
                                         alt="">
                                </label>
                                <div v-if="!field.image"
                                     style="cursor: pointer;font-weight: 399;font-size:9px;"
                                     class="upload-img"
                                     @click="chooseFilesGallery(index)">
                                    {{ $t('Upload Image') }}
                                </div>
                            </div>

                            <!-- <div v-bind:class="{ 'diableDiv': !hasPictures }"
                            v-if="!isView"
                                 class="upload-document mt-3"
                                 style="padding:0px !important"
                                 tile>

                                <img class="plus-img"
                                     style="cursor: pointer;"
                                     @click="addBoatPicture()"
                                     :src="require(`@/assets/images/plus.png`)"
                                     alt="">

                            </div> -->
                        </v-row>


                    </v-col>
                </v-row>
                <div class="mt-5 mx-2"
                     v-if="!isView">
                    <v-btn class="mr-4 text-lower text-color text-green"
                           outlined
                           color="#00B2A9"
                           @click="back('boat-condition')">
                        {{ $t('Back') }}
                    </v-btn>
                    <v-btn color="primary text-lower owner-info-btn border-card"
                           @click="submitPicture">
                        {{ $t('Submit') }}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';
import darkMode from '@core/utils/modalColor';
import router from '@/router';
import boatServices from '@/services/boat-services';
import store from '@/store';
import
{
    mdiPlus, mdipluscircle
} from '@mdi/js';
export default {
    props: {
        boatPictures: {
            type: Array,
            default: function ()
            {
                return [];
            }
        },
        boatGalleryUpload: {
            type: Array,
            default: function ()
            {
                return [];
            }
        },
        hasPictures: {
            default: true,
            type: Boolean
        },

    },

    data: () => ({
        isView: false,
        image: null,
        fileIndex: null
    }),
    mounted()
    {
        if (router.currentRoute.params.type == 'view')
        {
            this.isView = true;
        }
        if (router.currentRoute.params.type == 'pre')
        {
            this.isView = true;
        }
        else if(router.currentRoute.params.type == 'update')
        {
            // setTimeout(() => {
            //     this.boatPictures.push({ image: '' });
            // }, 600);
        }
        else
        {
            this.boatPictures.push({ image: '' });
        }
    },
    methods: {
        back(value)
        {
            this.$emit('backToPage', value);
        },
        perviewFile(element)
        {
            if (element.id)
            {
                window.open(element.image, '_blank');
            }
        },
        submitPicture()
        {
            
            // console.log(this.boatGalleryUpload);
            if (this.boatGalleryUpload.length)
            {
                const formData = new FormData();
                formData.append('boat_id', store.get('boatRegistrations/boatCreationId'));
                this.boatGalleryUpload.forEach(element =>
                {
                    formData.append('boat_pictures', element);
                });
                this.$emit('submitPicture', formData);

            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: '',
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    text: 'Please select image',
                }).then((result) =>
                {
                });
                return;
            }


        },
      
        uploadPicture(event)
        {
            // console.log(this.formFields);
            let file = event.target.files[0];
            let type = file.type.split('/');
            if (type[1] == 'png' || type[1] == 'jpeg' || type[1] == 'jpg')
            {
                this.boatGalleryUpload.push(event.target.files[0]);

                return new Promise((resolve, reject) =>
                {
                    let fileSize = event.target.files[0].size / 1024 / 1024;
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = () =>
                    {
                        this.image = reader.result;
                        this.boatPictures[this.fileIndex].image = this.image;
                        this.boatPictures[this.fileIndex].name = event.target.files[0].name;
                        this.hasPictures = false;
                        this.boatPictures.push({ image: '' });
                        // this.packageFile = base64Image.replace(/^data\:([^\;]+)\;base64,/gmi, '');
                        // // console.log(this.packageFile);
                        // this.uploadcardLimitImage(base64Image, file.size, file.name)

                    };
                })
            }
            else
            {
                this.$swal({
                    icon: 'error',
                    title: 'Boat Picture',
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    text: 'Invalid Format!'
                }).then((result) =>
                {

                });
            }

        },
        chooseFilesGallery: function (index)
        {
            this.fileIndex = index;
            document.getElementById("boatGallery").click()
        },
        deleteBoatPicture(element, index)
        {

            if (element.id)
            {
                let obj = {
                    "id": element.id,
                    "boat_id": store.get('boatRegistrations/boatCreationId')
                }
                boatServices.removeBoatPicture(obj).then(res =>
                {
                    if (res.status == 200)
                    {
                        let image;
                        this.boatPictures.forEach((element, i) =>
                        {
                            if (index == i)
                            {
                                image = element.name;
                            }
                        });
                        this.boatGalleryUpload.forEach((element, j) =>
                        {
                            if (element.name == image)
                            {
                                this.boatGalleryUpload.splice(j, 1);
                            }
                        });
                        this.boatPictures.splice(index, 1);
                        this.$swal({
                            title: ``,
                            text: 'Boat Picture Deleted successfully.',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then((result) =>
                        {
                            // router.push('/myboat/list')
                        });
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: '',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                });

            }
            else
            {
                let image;
                this.boatPictures.forEach((element, i) =>
                {
                    if (index == i)
                    {
                        image = element.name;
                    }
                });
                this.boatGalleryUpload.forEach((element, j) =>
                {
                    if (element.name == image)
                    {
                        this.boatGalleryUpload.splice(j, 1);
                    }
                });
                this.boatPictures.splice(index, 1);


            }

        },
        addBoatPicture()
        {
            this.boatPictures.push({
                image: ''
            })
            this.hasPictures = false;
        },
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        boatPictures: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = Object.assign({}, newVal);
                if (this.localObject)
                {

                }
            }
        }
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
            icons: {
                mdiPlus,
                mdipluscircle
            },
        }

    }

}
</script>

<style lang="scss" scoped>
.button-row {
    display: flex;
    flex-wrap: wrap;
}

.button {
    flex-basis: 100%;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 4px;
}

.only-divider .v-divider {
    border-width: 1px !important;
}

.text-blue {
    color: #006fcf;
}

.file-box {
    background: #FFFFFF;
    width: 36px;
    height: 36px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    text-align: center;
}

.text-black {
    color: #000;
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.green-text {
    color: #00B2A9
}

.plus-img {
    margin: auto;
    /* height: 101px; */
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: center; */
    height: 50px;
    margin-top: 46px;
}

.secure-img {
    background: #FFFFFF;
    width: 43px;
    height: 41px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
    position: absolute;
    right: 9px;
    top: 5px;
}

.attach-file {
    height: 20px;
    margin: 4px;
}

.license-color {
    color: #00B2A9
}

.document {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.owner-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.packages {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.boat-condition {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.cross {
    width: 24px;
    height: 22px;
    position: absolute;
    right: -12px;
}

.upload-document {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
    // height:154px;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 38px 0px;
}


.dummy-img {
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 35px 38px 0px;
}

.bg-white {
    background: white;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.bg {
    background: #f1f1f1;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

.category-text {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 24px;
    right: 24px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.document-icon {
    position: absolute;
    top: 50px;
    right: 25px;
    width: 88px;
    height: 56px;
}

.diableDiv {
    pointer-events: none;
    opacity: 0.4;
}

.text-fields {
    margin: -4px 0px 0px;
}

.text-color {
    color: #00B2A9;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .code-select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    caret-color: none !important;
}

::v-deep .country-selector__label[data-v-46e105de] {
    position: absolute;
    top: 3px;
    cursor: pointer;
    display: none !important;
    left: 11px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474;
}

::v-deep .input-tel.is-focused {
    border-color: none !important;
    caret-color: none !important;
}



::v-deep .input-tel__input[data-v-e59be3b4] {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
    border-top: none !important;
    border-right: none !important;
    margin-left: -1px;
    height: 40px;
    min-height: 40px;
    border-left: none !important;
    border-color: none !important;
}



::v-deep .input-tel.has-hint .input-tel__label[data-v-e59be3b4],
.input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}


::v-deep .input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}

::v-deep .country-selector__input[data-v-46e105de] {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 13px;
    border-top: none !important;
    z-index: 0;
    padding-left: 8px;
    color: #000;
    border-left: none !important;
    border-color: none !important;
}
</style>