<template>
    <div>
        <packageDialog :modal="modal"
                       :packageInfos="ownerPackage"
                       @closed="closed"></packageDialog>
        <v-form v-if="loadPage"
                class="mt-0 multi-col-validation"
                lazy-validation
                ref="packageForm"
                v-model="packageField">
            <v-card>
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                    <div class="d-flex align-center pb-5">
                        <div class="d-flex align-center">
                            <span class="font-weight-semibold text-base text-green">
                                {{ $t('Pricing') }}
                            </span>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="d-flex align-center pb-5">

                    </div>
                </v-card-text>

                <v-card-text>
                    <v-row class="">
                        <v-col v-if="category_type != -1"
                               cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Booking Type') }}
                            </p>
                            <v-text-field :placeholder="$t('Booking Type')"
                                          variant="underlined"
                                          :disabled="view"
                                          :readonly="true"
                                          class="text-fields"
                                          v-model="bookingType"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ category_type == '0' ? $t('Day Range') : $t('Slot Time') }}
                            </p>
                            <v-text-field v-if="category_type == '0'"
                                          :placeholder="category_type == '0' ? $t('Day Range') : $t('Slot Time')"
                                          variant="underlined"
                                          :disabled="view"
                                          :suffix="category_type == '0' ? 'Day(s)' : 'Hour(s)'"
                                          :readonly="true"
                                          class="text-fields"
                                          v-model="otherSlotTime"></v-text-field>

                            <v-select v-if="category_type == '1'"
                                      :items="slotTimeValues"
                                      variant="underlined"
                                      item-text="title"
                                      item-value="value"
                                      item-disabled="disabled"
                                      :placeholder="category_type == '0' ? $t('Day Range') : $t('Slot Time')"
                                      :disabled="view"
                                      :rules="[genericRules.required]"
                                      class="text-fields"
                                      v-model="myboat.slot_time"></v-select>
                        </v-col>

                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Select Price (SAR)') }}
                            </p>
                            <v-text-field :placeholder="$t('Enter Price in SAR')"
                                          variant="underlined"
                                          :disabled="view"
                                          @change="onPriceChange()"
                                          @keydown="handleKeyDown"
                                          onkeydown="javascript: return event.keyCode == 69 ? false : true"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="myboat.price"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                Additional Time Rate
                            </p>
                            <v-text-field :placeholder="$t('Enter Price in SAR')"
                                          variant="underlined"
                                          :disabled="view"
                                          @change="onRateChange()"
                                          @keydown="handleKeyDown"
                                          :rules="[genericRules.required]"
                                          class="text-fields"
                                          v-model="myboat.additional_period_rate"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12"
                               v-if="ownerRole">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('MyBoat Commission') }}
                            </p>
                            <v-select :items="percentageValues"
                                      variant="underlined"
                                      :item-text="`name`"
                                      :placeholder="$t('Enter Percentage (0-100%)')"
                                      :disabled="view"
                                      suffix="%"
                                      :rules="[genericRules.required]"
                                      class="text-fields"
                                      v-model="myboat.percentage"></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="pa-3 mt-4">
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                    <div class="d-flex align-center pb-5">
                        <div class="d-flex align-center">
                            <span class="font-weight-semibold text-base text-green">
                                {{ $t('Packages') }}
                            </span>
                        </div>
                    </div>

                    <v-spacer></v-spacer>

                    <div class="d-flex align-center pb-5">

                    </div>
                </v-card-text>

                <v-card-text>
                    <v-row class="">
                        <v-col cols="12"
                               lg="4"
                               md="6"
                               sm="12">
                            <p class="font-weight-semibold mb-0 text-black">
                                {{ $t('Package Name') }}
                            </p>
                            <v-select :items="packagesList"
                                      variant="underlined"
                                      multiple
                                      :item-text="`name`"
                                      :disabled="view"
                                      item-value="id"
                                      :rules="[genericRules.required]"
                                      @change="addPackage"
                                      class="text-fields"
                                      v-model="myboat.package_name"></v-select>
                        </v-col>

                        <v-col cols="12"
                               md="6"
                               lg="8"
                               sm="12"></v-col>


                        <v-col cols="12"
                               lg="10"
                               md="8"
                               sm="12">
                            <span class="font-weight-semibold text-color-black"
                                  style="font-size: 13px;">
                                {{ $t('Added Packages') }}
                            </span>

                            <div class="package-item mt-3">
                                <v-chip small
                                        v-for="( field, index ) in  packageItems"
                                        :key="index"
                                        label
                                        @click="viewPackage(field)"
                                        :disabled="view"
                                        class="mr-3 mt-1"
                                        style="background-color: #F3F3F3; color: black">
                                    {{ field.name }} &nbsp <span @click="removePackage(index)"
                                          class="cross">x</span>
                                </v-chip>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="mt-5"
                         v-if="!view">
                        <v-btn @click="back('ownerinfo')"
                               class="mr-4 text-lower text-color text-green"
                               outlined
                               color="#00B2A9">
                            {{ $t('Back') }}
                        </v-btn>
                        <v-btn color="primary"
                               class="text-lower owner-info-btn border-card"
                               :disabled="!packageField"
                               @click="addNewPackage">
                            {{ $t('Next') }}
                        </v-btn>
                    </div>

                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script>

import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import router from '@/router';
import packageServices from '@/services/package-services';
import packageDialog from '@/views/dialogs/PackageDialouge.vue';
import
{
    mdiPlus, mdipluscircle
} from '@mdi/js';
import store from '@/store';

import createNumberMask from "text-mask-addons/dist/createNumberMask";


export default {
    props: {
        ratesPackage: {
            default()
            {
                return {}
            },
            type: Object
        }
    },
    components: {
        packageDialog
    },
    data: () => ({
        loadPage: false,
        currencyMask: createNumberMask({
            prefix: "",
            includeThousandsSeparator: true,
            allowNegative: false,
            allowDecimal: true,
            decimalLimit: 2,
            thousandsSeparatorSymbol: ",",
        }),
        inputValue: '44,444,444',
        inputValueRate: null,
        percentageValues: [],
        modal: false,
        packageForm: null,
        ownerPackage: {},
        packageField: false,
        category_type: -1,
        view: false,
        genericRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
        },
        packagesList: [],
        package_name: [],
        packageItems: [],
        ownerRole: true,
        bookingType: 'Hourly',
        otherSlotTime: '',
        myboat: {
            slot_time: '',
            price: '',
            percentage: '',
            package_name: '',
            additional_period_rate: ''
        },
        slotTimeValues: [
            {
                title: '1 Hour',
                value: '01:00:00',
                disabled: false
            },
            {
                title: '2 Hours',
                value: '02:00:00',
                disabled: false
            },
            {
                title: '3 Hours',
                value: '03:00:00',
                disabled: false
            },
            {
                title: '4 Hours',
                value: '04:00:00',
                disabled: false
            },
            {
                title: '5 Hours',
                value: '05:00:00',
                disabled: false
            },
            {
                title: '6 Hours',
                value: '06:00:00',
                disabled: false
            },
            {
                title: '7 Hours',
                value: '07:00:00',
                disabled: false
            },
            {
                title: '8 Hours',
                value: '08:00:00',
                disabled: false
            },
            {
                title: '9 Hours',
                value: '09:00:00',
                disabled: false
            },
            {
                title: '10 Hours',
                value: '10:00:00',
                disabled: false
            },
            {
                title: '11 Hours',
                value: '11:00:00',
                disabled: false
            },
            {
                title: '12 Hours',
                value: '12:00:00',
                disabled: false
            }
        ],
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'boatRegistrations/SET_SLOT_TIME')
            {
                this.myboat.slot_time = mutation.payload;
                this.otherSlotTime = mutation.payload;
            }

            if (mutation.type === 'boatRegistrations/SET_BOOKING_TYPE')
            {
                this.category_type = parseInt(mutation.payload);
                if (this.category_type == 0)
                {
                    this.bookingType = 'Daily';
                    this.otherSlotTime = '01';
                    this.myboat.slot_time = '24:00:00'
                }
                else if (this.category_type == 1)
                {
                    this.bookingType = 'Hourly';

                    if (this.myboat.slot_time)
                    {
                        this.slotTimeValues.forEach((element, index) =>
                        {
                            element.disabled = true;
                            let v = this.myboat.slot_time.split(':');
                            let num = parseInt(v[0]);

                            if (num <= index + 1)
                            {
                                element.disabled = false;
                            }
                        });
                    }
                }
            }
        })
    },
    mounted()
    {
        this.category_type = store.get('boatRegistrations/bookingType');

        for (let i = 1; i <= 99; i++)
        {
            this.percentageValues.push(i);
        }

        if (this.packageItems == undefined)
        {
            this.packageItems = [];
        }
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData.role == 'boat_owner')
        {
            this.ownerRole = false;
        }
        if (router.currentRoute.params.id)
        {
            store.set('boatRegistrations/boatCreationId', router.currentRoute.params.id);
        }
        if (router.currentRoute.params.type == 'view')
        {
            this.view = true;
        }

        if (router.currentRoute.params.type == 'pre')
        {
            this.view = true;
        }

        if (this.category_type == 0)
        {
            this.bookingType = 'Daily';
            this.myboat.slot_time = '24:00:00';
        }
        else if (this.category_type == 1)
        {
            this.bookingType = 'Hourly';
            this.myboat.slot_time = store.get('boatRegistrations/slotTime');
        }

        setTimeout(() =>
        {
            this.loadPage = true;
            let owner_id = store.get('boatRegistrations/owner_id');
            this.getPackages(owner_id);
        }, 3000);
    },
    methods: {
        handleKeyDown(event)
        {
            // Get the pressed key's keycode
            const keyCode = event.keyCode || event.which;

            // Allow only numeric keys (0-9) or certain special keys (e.g., backspace, delete)
            if (
                (keyCode >= 48 && keyCode <= 57) || // Numeric keys 0-9
                keyCode === 8 || // Backspace
                keyCode === 9 || // Tab
                keyCode === 46 || // Delete
                keyCode === 37 || // Left arrow
                keyCode === 39 // Right arrow
            )
            {
                // Allow the keypress
                return true;
            } else
            {
                // Prevent the default behavior (character input)
                event.preventDefault();
            }
        },
        onPriceChange()
        {
            console.log(this.myboat.price)
            if (this.myboat.price)
            {
                this.myboat.price = parseFloat(this.myboat.price.replace(/,/g, '')).toLocaleString("en-SA", { style: "currency", currency: "SAR" }).replace('SAR', '').trim();
                console.log(this.myboat.price);
            }
        },
        onRateChange()
        {
            if (this.myboat.additional_period_rate)
            {
                this.myboat.additional_period_rate = parseFloat(this.myboat.additional_period_rate.replace(/,/g, '')).toLocaleString("en-SA", { style: "currency", currency: "SAR" }).replace('SAR', '').trim();
                console.log(this.myboat.additional_period_rate);
            }
        },
        closed()
        {
            this.modal = false;
        },
        back(value)
        {
            this.$emit('backToPage', value);
        },
        viewPackage(element)
        {
            this.modal = true;
            this.ownerPackage = element;
        },
        getPackages(owner_id)
        {
            packageServices.getPackages(owner_id).then(resp =>
            {
                this.packagesList = resp.data.boat_owner_packages;
            })
        },
        removePackage(index)
        {
            this.packageItems?.splice(index, 1);
            this.myboat.package_name?.splice(index, 1);
        },
        addPackage(value)
        {
            let newVal = [];
            let oldVal = [];
            value.forEach(element =>
            {
                oldVal.push(element);
                newVal.push(element);
            });

            if (this.packageItems == undefined)
            {
                this.packageItems = [];
            }
            else 
            {
                this.packageItems.forEach(element =>
                {
                    newVal.forEach((value, index) =>
                    {
                        if (value == element.id)
                        {
                            newVal.splice(index, 1)
                        }
                    });
                });
            }

            if (newVal.length == 0)
            {
                newVal = oldVal;
                this.packageItems = [];
            }
            this.packagesList.forEach(element =>
            {
                newVal.forEach(value =>
                {
                    if (value == element.id)
                    {
                        this.packageItems.push({ name: element.name, id: element.id });
                    }
                });
            });
        },

        addNewPackage()
        {
            if (this.category_type == 0)
            {
                // show date range
                this.bookingType = 'Daily';
                this.myboat.slot_time = '24:00:00';
            }
            else if (this.category_type == 1)
            {
                // show slots
                this.bookingType = 'Hourly';
                this.myboat.slot_time = store.get('boatRegistrations/slotTime');
            }
            console.log(this.myboat)
            const isFormValid = this.$refs.packageForm.validate()
            if (!isFormValid) return

            let obj = {
                boat_id: store.get('boatRegistrations/boatCreationId'),
                slot_time: this.myboat.slot_time,
                slot_price: this.myboat.price.replace(/,/g, ''),
                commission: this.myboat.percentage,
                packageItems: this.packageItems,
                additional_period_rate: this.myboat.additional_period_rate.replace(/,/g, '')
            }
            this.$emit('savePackageInfo', obj);
        },
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        ratesPackage: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = Object.assign({}, newVal);
                if (this.localObject)
                {
                    this.myboat.slot_time = this.localObject.slot_time ? this.localObject.slot_time : '';
                    this.myboat.min_slot_time = this.localObject.min_slot_time ? this.localObject.min_slot_time : '';

                    if (this.localObject.price)
                    {
                        let bp = this.localObject.price.toString();
                        this.myboat.price = bp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    if (this.localObject.additional_period_rate)
                    {
                        let bp = this.localObject.additional_period_rate.toString();
                        this.myboat.additional_period_rate = bp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                    this.myboat.percentage = this.localObject.percentage;
                    this.packageItems = this.localObject.packageItems;
                    this.myboat.package_name = this.localObject.packageName;
                    // this.myboat.additional_period_rate = this.localObject.additional_period_rate;

                    this.onPriceChange();
                    this.onRateChange();
                }
            }
        }
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
            icons: {
                mdiPlus,
                mdipluscircle
            },
        }

    }
}

</script>

<style lang="scss" scoped>
.text-black {
    color: #000;
}

.cross {
    width: 24px;
    height: 22px;
    position: absolute;
    right: -12px;
}

.text-fields {
    margin: -4px 0px 0px;
}

.text-color {
    color: #00B2A9;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .code-select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    caret-color: none !important;
}

::v-deep .country-selector__label[data-v-46e105de] {
    position: absolute;
    top: 3px;
    cursor: pointer;
    display: none !important;
    left: 11px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474;
}

::v-deep .input-tel.is-focused {
    border-color: none !important;
    caret-color: none !important;
}



::v-deep .input-tel__input[data-v-e59be3b4] {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
    border-top: none !important;
    border-right: none !important;
    margin-left: -1px;
    height: 40px;
    min-height: 40px;
    border-left: none !important;
    border-color: none !important;
}



::v-deep .input-tel.has-hint .input-tel__label[data-v-e59be3b4],
.input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}


::v-deep .input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}

::v-deep .country-selector__input[data-v-46e105de] {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 13px;
    border-top: none !important;
    z-index: 0;
    padding-left: 8px;
    color: #000;
    border-left: none !important;
    border-color: none !important;
}
</style>