<template>
    <div>

        <div class="d-flex pb-2">
            <div>
                <v-icon @click="backToList"
                        class="arrow-color"
                        style="cursor: pointer;">mdi-arrow-left</v-icon>
            </div>
            <div class="align-center">
                <p v-if="!view"
                   style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                    {{ $t(type) }} {{ $t('Boat') }}
                </p>
                <p v-if="view"
                   style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                    {{ $t('View Boat') }}
                </p>
            </div>
        </div>
        <v-divider></v-divider>


        <v-card class="mt-7 d-lg-flex flex-row">
            <v-card-text class="pa-4">
                <div class="d-flex justify-space-between button-row">

                    <div class="d-flex flex-column justify-center">
                        <div style="cursor:pointer;"
                             class="font-weight-semibold">
                            <span class="d-flex">
                                <div @click="boatTabs('info', 'next')"
                                     class="button"
                                     v-bind:class="{ 'boat-tab': step == 1, 'green-text': step != 1 }">
                                    {{ $t('General Boat Information') }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="d-flex"
                         style="height: 30px;">
                        <v-divider class="my-2 d-none d-sm-flex"
                                   :vertical="true"></v-divider>
                    </div>
                    <div v-bind:class="{ 'diableDiv': !boatOwnerFields }"
                         class="d-flex flex-column justify-center">
                        <div style="cursor:pointer;"
                             class="font-weight-semibold">
                            <span class="d-flex">
                                <div @click="boatTabs('ownerinfo', 'next')"
                                     class="button"
                                     v-bind:class="{ 'owner-info': ownerinfo, 'green-text': !ownerinfo }">
                                    {{ $t('Owner Information') }}
                                </div>
                            </span>
                        </div>
                    </div> -->
                    <div class="d-flex"
                         style="height: 30px;">
                        <v-divider class="my-2 d-none d-sm-flex"
                                   :vertical="true"></v-divider>
                    </div>
                    <div v-bind:class="{ 'diableDiv': !packageInfo }"
                         class="d-flex flex-column justify-center">
                        <div style="cursor:pointer;"
                             class="text-blue font-weight-semibold">
                            <span class="d-flex">
                                <div @click="boatTabs('packages', 'next')"
                                     class="button"
                                     v-bind:class="{ 'boat-tab': step == 2, 'green-text': step != 2 }">
                                    {{ $t('Rates & Packages') }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex"
                         style="height: 30px;">
                        <v-divider class="my-2 d-none d-sm-flex"
                                   :vertical="true"></v-divider>
                    </div>
                    <div v-bind:class="{ 'diableDiv': !conditionInfo }"
                         class="d-flex flex-column justify-center">
                        <div style="cursor:pointer;"
                             class="text-blue font-weight-semibold">
                            <span class="d-flex">
                                <div @click="boatTabs('boat-condition', 'next')"
                                     class="button"
                                     v-bind:class="{ 'boat-tab': step == 3, 'green-text': step != 3 }">
                                    {{ $t('Boat Condition') }}
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex"
                         style="height: 30px;">
                        <v-divider class="my-2 d-none d-sm-flex"
                                   :vertical="true"></v-divider>
                    </div>
                    <div v-bind:class="{ 'diableDiv': !documentsInfo }"
                         class="d-flex flex-column justify-center">
                        <div style="cursor:pointer;"
                             class="text-blue font-weight-semibold">
                            <span class="d-flex">
                                <div @click="boatTabs('document', 'next')"
                                     class="button"
                                     v-bind:class="{ 'boat-tab': step == 4, 'green-text': step != 4 }">
                                    {{ $t('Upload Documents') }}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>

        <v-stepper v-model="step"
                   class="mt-4">
            <v-stepper-content step="1">
                <div class="d-flex align-center">
                    <span class="font-weight-semibold text-base text-green">
                        {{ $t('General Boat Information') }}
                    </span>
                </div>
                <boatInfomation :boatInformation="boatInformation"
                                @saveBoatInfo="saveBoatInfo"
                                @updateBoatInfo="updateBoatInfo"></boatInfomation>
            </v-stepper-content>

            <!-- <v-stepper-content step="2">
                <div class="d-flex align-center">
                    <span class="font-weight-semibold text-base text-green">
                        {{ $t('Owner Information') }}
                    </span>
                </div>
                <ownerInfomation :ownerInformation="ownerInformation"
                                 @backToPage="boatTabs($event, 'back')"
                                 @saveBoatOwner="saveBoatOwner"></ownerInfomation>
            </v-stepper-content> -->

            <v-stepper-content step="2">
                <packageInfomation :ratesPackage="ratesPackage"
                                   @backToPage="boatTabs($event, 'back')"
                                   @savePackageInfo="savePackageInfo"></packageInfomation>
            </v-stepper-content>

            <v-stepper-content step="3">
                <p class="font-weight-semibold mb-0 license-color text-green">
                    {{ $t('Boat Condition & Features') }}
                </p>
                <boatCondition :boatConditions="boatConditions"
                               @backToPage="boatTabs($event, 'back')"
                               @saveBoatCondition="saveBoatCondition"></boatCondition>
            </v-stepper-content>

            <v-stepper-content step="4">
                <boatDocument @submitDocument="submitDocument"
                              @backToPage="boatTabs($event, 'back')"
                              :boatDocument="boatDocument"></boatDocument>

                <boatPicture :boatPictures="boatPictures"
                             @submitPicture="submitPicture"
                             @backToPage="boatTabs($event, 'back')"></boatPicture>
            </v-stepper-content>

        </v-stepper>

    </div>
</template>

<script>

import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import darkMode from '@core/utils/modalColor';

import boatInfomation from '@/views/myboat/myboat-add/BoatInformation.vue'
import ownerInfomation from '@/views/myboat/myboat-add/OwnerInformation.vue'
import packageInfomation from '@/views/myboat/myboat-add/RatesPackages.vue'
import boatCondition from '@/views/myboat/myboat-add/BoatCondition.vue'
import boatDocument from '@/views/myboat/myboat-add/BoatDocument.vue'
import boatPicture from '@/views/myboat/myboat-add/BoatPicture.vue'

import router from '@/router';
import boatServices from '@/services/boat-services';
import { mdiPlus, mdipluscircle } from '@mdi/js';

import store from '@/store';
import config from '/config';
export default {
    components: {
        PhoneNumberInput,
        boatInfomation,
        ownerInfomation,
        packageInfomation,
        boatCondition,
        boatDocument,
        boatPicture
    },
    data: () => ({
        boatOwnerField: false,
        view: false,
        boatInformation: {},
        ownerInformation: {},
        ratesPackage: {},
        boatConditions: {},
        boatPictures: [],
        boatDocument: [],
        type: 'Add',
        packageItems: [],
        step: 1,
        ownerRole: true,

        filterPreBoats: 'onboarding',

        formProgress: 1,
        packageInfo: false,
        conditionInfo: false,
        documentsInfo: false,
    }),
    mounted()
    {
        this.type = router.currentRoute.params.type;
        if (this.type == undefined)
        {
            this.type = 'Add'
        }
        if (this.type == 'update')
        {
            this.type = 'Update'
        }
        if (router.currentRoute.params.type == 'view')
        {
            this.view = true;
        }
        if (router.currentRoute.params.type == 'pre')
        {
            this.view = true;
            this.filterPreBoats = 'pre-onboarding';
        }
        else
        {
            this.filterPreBoats = 'onboarding';
        }
        if (router.currentRoute.params.id)
        {
            store.set('boatRegistrations/boatCreationId', router.currentRoute.params.id);
            this.getBoatData();

            if (this.filterPreBoats == 'onboarding')
            {
                this.getBoatPicture();
                this.getBoatDocument();
            }
            this.packageInfo = true;
            this.conditionInfo = true;
            this.documentsInfo = true;
        }
    },
    methods: {
        backToList()
        {
            router.push('/boat/list')
        },

        boatTabs(value, type)
        {
            if (type == 'back')
            {
                this.step--;
            }
            else
            {
                if (value == 'info')
                {
                    this.step = 1;
                }
                if (value == 'ownerinfo')
                {
                    this.step = 2;
                }
                if (value == 'packages')
                {
                    this.step = 2;
                }
                if (value == 'boat-condition')
                {
                    this.step = 3;
                }
                if (value == 'document')
                {
                    this.step = 4;
                }
            }

        },

        getBoatData()
        {
            // console.log('boatServices.getBoatData', router.currentRoute.params.id);
            boatServices.getBoatData(router.currentRoute.params.id, this.filterPreBoats).then(res =>
            {
                // console.log('boatServices.getBoatData', res);

                if (res.data.statusCode == 200)
                {
                    // boat info////
                    let boatResponse = res.data.data.boats[0];

                    if (this.filterPreBoats == 'pre-onboarding')
                    {
                        boatResponse['boat_documents'] = boatResponse.pre_boat_documents;
                        boatResponse['boat_pictures'] = boatResponse.pre_boat_pictures;
                        boatResponse['boat_pricing'] = boatResponse.pre_boat_pricing;
                        boatResponse['boats_condition'] = boatResponse.pre_boats_condition;
                        boatResponse['boats_owner'] = boatResponse.pre_boats_owner;
                        boatResponse['boat_packages'] = boatResponse.pre_boat_owner_packages;

                        if(boatResponse.boat_documents)
                        {
                            boatResponse.boat_documents.forEach((element, index) =>
                            {
                                if (element.document != null)
                                {
                                    let fileType = element.document.split('.');
                                    this.boatDocument.push({
                                        image: config.MYBOAT_IMG_URL + element.document,
                                        id: element.id,
                                        type: fileType[1] == 'pdf' ? 'pdf' : fileType[1] == 'docx' ? 'vnd.openxmlformats-officedocument.wordprocessingml.document' : fileType[1] == 'pptx' ? 'vnd.openxmlformats-officedocument.presentationml.presentation' : fileType[1] == 'xlsx' ? 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ''
                                    });
                                }
                            });

                            this.boatDocument.push({ image: '' });
                        }

                        if (boatResponse.boat_pictures)
                        {
                            boatResponse.boat_pictures.forEach((element, index) =>
                            {
                                this.boatPictures.push({
                                    image: config.MYBOAT_IMG_URL + element.image,
                                    id: element.id
                                });
                            });
                            this.boatPictures.push({ image: '' });
                        }
                        
                    }
                    this.boatInformation = {
                        boat_name: boatResponse.boat_name,
                        boat_name_ar: boatResponse.boat_name_ar,
                        category_id: parseInt(boatResponse.category_id),
                        captain_id: boatResponse.captain_id,
                        description: boatResponse.description,
                        description_ar: boatResponse.description_ar,
                        engine_model: boatResponse.engine_model,
                        boat_brand: boatResponse.boat_brand,
                        maintenance_time: boatResponse.maintenance_time,
                        latLngInput: boatResponse.latitude + ',' + boatResponse.longitude,
                        latitude: boatResponse.latitude,
                        longitude: boatResponse.longitude,
                        year: boatResponse.year ? boatResponse.year : '',
                        city_id: boatResponse.city?.id,
                        location: boatResponse.location,
                        boat_modal: boatResponse.boat_modal,
                        owner_id: boatResponse.boats_owner?.user_id,
                        status: boatResponse.status,
                        boat_length: boatResponse.boat_length,
                        marina_id: parseInt(boatResponse.marina_id),
                    }
                    if (this.filterPreBoats == 'pre-onboarding')
                    {
                        this.boatInformation['owner_name'] = boatResponse?.boats_owner?.owner_name;
                    }
                    store.set('boatRegistrations/owner_id', this.boatInformation.owner_id);

                    //owner info//////
                    // this.ownerInformation = {
                    //     ownerId: boatResponse?.boats_owner?.id,
                    //     owner_name: boatResponse?.boats_owner?.owner_name,
                    //     type: boatResponse?.boats_owner?.owner_type,
                    //     certification: boatResponse?.boats_owner?.certificate,
                    //     id_no: boatResponse?.boats_owner?.information_id,
                    //     country_id: +boatResponse?.boats_owner?.country_id,
                    //     unit: boatResponse?.boats_owner?.unit,
                    //     street: boatResponse?.boats_owner?.address,
                    //     district: boatResponse?.boats_owner?.district,
                    //     structure_number: boatResponse?.boats_owner?.structure_number,
                    //     owner_city: +boatResponse?.boats_owner?.city,

                    //     bank_phone: boatResponse?.boats_owner?.phone,
                    //     bank_email: boatResponse?.boats_owner?.email,
                    //     bank_name: boatResponse?.boats_owner?.bank_name,
                    //     branch: boatResponse?.boats_owner?.bank_branch,
                    //     iban: boatResponse?.boats_owner?.bank_iban
                    // }



                    //boat condition//////
                    let license_number = boatResponse?.boats_condition?.license_number;
                    this.boatConditions = {
                        license_number: license_number == undefined ? '' : license_number,
                        report: boatResponse?.boats_condition?.service_report,
                        anti_fouling_date: boatResponse?.boats_condition?.anti_fouling_date,
                        paint: boatResponse?.boats_condition?.paint ? '1' : '0',
                        rooms: boatResponse?.boats_condition?.rooms ? '1' : '0',
                        anti_fouling: boatResponse?.boats_condition?.anti_fouling ? '1' : '0',
                        polish: boatResponse?.boats_condition?.polish ? '1' : '0',
                        wash_room: boatResponse?.boats_condition?.wash_room ? '1' : '0',
                        cleanliness: boatResponse?.boats_condition?.cleanliness ? '1' : '0',
                        life_jacket: boatResponse?.boats_condition?.life_jacket ? '1' : '0',
                        salon: boatResponse?.boats_condition?.salon ? '1' : '0',
                        smoke: boatResponse?.boats_condition?.smoke ? '1' : '0',
                        fire_extinguisher: boatResponse?.boats_condition?.fire_extinguisher ? '1' : '0',
                        gear_sound_box: boatResponse?.boats_condition?.gear_sound_box ? '1' : '0',
                        air_conditioner: boatResponse?.boats_condition?.air_conditioner ? '1' : '0',
                        bimini: boatResponse?.boats_condition?.bimini ? '1' : '0',
                        usb_socket: boatResponse?.boats_condition?.usb_socket ? '1' : '0',
                        gps: boatResponse?.boats_condition?.gps ? '1' : '0',
                        depth_sounder: boatResponse?.boats_condition?.depth_sounder ? '1' : '0',
                    }

                    let packageName = [];
                    let items = boatResponse?.boat_packages;
                    items?.forEach(element =>
                    {
                        if (this.filterPreBoats == 'pre-onboarding')
                        {
                            this.packageItems.push({name: element.name});
                            packageName.push({name: element.name});
                        }
                        else
                        {
                            this.packageItems.push(element.boat_owner_package);
                            packageName.push(element.boat_owner_package);
                        }
                    });

                    // console.log(boatResponse);
                    this.ratesPackage = {
                        min_slot_time: boatResponse?.category ? boatResponse?.category.min_time_slot ? boatResponse?.category.min_time_slot : '' : '',
                        slot_time: boatResponse.boat_pricing ? boatResponse?.boat_pricing?.slot_time ? boatResponse?.boat_pricing?.slot_time : '' : '',
                        price: boatResponse.boat_pricing ? boatResponse?.boat_pricing?.slot_price ? boatResponse?.boat_pricing?.slot_price : '' : '',
                        percentage: boatResponse.boat_pricing ? boatResponse?.boat_pricing?.commission ? boatResponse?.boat_pricing?.commission : '' : '',
                        packageItems: this.packageItems,
                        packageName: packageName,
                        additional_period_rate: boatResponse.boat_pricing ? boatResponse?.boat_pricing?.additional_period_rate ? boatResponse?.boat_pricing?.additional_period_rate : '' : '',
                    }

                    // this.isLoadedBoat = true;
                }

            });
        },
        getBoatDocument()
        {
            boatServices.getBoatDocument(store.get('boatRegistrations/boatCreationId'), this.filterPreBoats).then(res =>
            {
                if (res.status == 200)
                {
                    if (res.data.data.boat.boat_documents)
                    {
                        res.data.data.boat.boat_documents.forEach((element, index) =>
                        {
                            if (element.document != null)
                            {
                                let fileType = element.document.split('.');
                                this.boatDocument.push({
                                    image: config.MYBOAT_IMG_URL + element.document,
                                    id: element.id,
                                    type: fileType[1] == 'pdf' ? 'pdf' : fileType[1] == 'docx' ? 'vnd.openxmlformats-officedocument.wordprocessingml.document' : fileType[1] == 'pptx' ? 'vnd.openxmlformats-officedocument.presentationml.presentation' : fileType[1] == 'xlsx' ? 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' : ''
                                });
                            }
                        });
                    }
                    this.boatDocument.push({ image: '' });
                }
            });
        },
        getBoatPicture()
        {

            boatServices.getBoatPicture(store.get('boatRegistrations/boatCreationId'), this.filterPreBoats).then(res =>
            {
                if (res.status == 200)
                {
                    if (res.data.data.boat.boat_pictures)
                    {

                        res.data.data.boat.boat_pictures.forEach((element, index) =>
                        {
                            this.boatPictures.push({
                                image: config.MYBOAT_IMG_URL + element.image,
                                id: element.id
                            });
                            // console.log(element.image)
                        });
                    }
                    // console.log(this.boatPictures);
                    this.boatPictures.push({ image: '' });
                }
            });
        },

        saveBoatInfo(event)
        {
            boatServices.submitBoat(event).then(res =>
            {
                if (res?.data?.statusCode == 200)
                {
                    this.handleResponse('General information added successfully', 'success', 2);

                    store.set('boatRegistrations/boatCreationId', res.data.data.boat.id);
                    this.packageInfo = true;
                }
                else
                {
                    // this.handleResponse('Something went wrong!', 'error');
                    let msg = 'Something went wrong!';

                    // if(res?.error?.response?.data?.message)
                    // {
                    //     msg = res?.error?.response?.data?.message
                    // }
                    this.handleResponse(msg, 'error');
                }
            });
        },
        updateBoatInfo(event)
        {
            boatServices.updateBoat(event).then(res =>
            {
                if (res?.data?.statusCode == 200)
                {
                    this.handleResponse('General information updated successfully', 'success', 2);
                    this.packageInfo = true;
                }
                else
                {
                    let msg = 'Something went wrong!';

                    // if(res?.error?.response?.data?.message)
                    // {
                    //     msg = res?.error?.response?.data?.message
                    // }
                    this.handleResponse(msg, 'error');
                }
            });
        },
        savePackageInfo(event)
        {
            let obj;
            if (this.ownerRole)
            {
                obj = {
                    "boat_id": event.boat_id,
                    "slot_time": event.slot_time,
                    "slot_price": event.slot_price,
                    "commission": event.commission,
                    additional_period_rate: event.additional_period_rate
                }
            }
            else
            {
                obj = {
                    "boat_id": event.boat_id,
                    "slot_time": event.slot_time,
                    "slot_price": event.slot_price,
                    additional_period_rate: event.additional_period_rate
                }
            }
            boatServices.submitPricing(obj).then(res =>
            {
                if (res?.status == 200)
                {
                    this.handleResponse('Rates and packages added successfully', 'success', 3);
                    this.packageInfo = true;
                    this.conditionInfo = true;

                }
                else
                {
                    this.handleResponse(res.error.response.data.message, 'error');
                }
            });

            if (event.packageItems)
            {
                let tags = [];
                event.packageItems.forEach(element =>
                {
                    tags.push(element.id);
                });
                let obj = {
                    "boat_id": store.get('boatRegistrations/boatCreationId'),
                    "package_ids": tags ? tags.join() : null,
                }
                boatServices.submitPackage(obj).then(res =>
                {
                    if (res.statusCode == 200)
                    {

                    }
                    else
                    {
                    }
                });
            }
        },
        saveBoatCondition(event)
        {
            boatServices.submitBoatCondition(event).then(res =>
            {
                if (res.statusCode == 200)
                {
                    this.handleResponse('Boat condition added successfully', 'success', 4);
                    this.packageInfo = true;
                    this.conditionInfo = true;
                    this.documentsInfo = true;
                }
                else
                {
                    this.handleResponse('Something went wrong!', 'error');
                }
            });

        },
        submitPicture(event)
        {
            boatServices.submitBoatGallery(event).then(res =>
            {
                if (res.status == 200)
                {
                    this.$swal({
                        title: ``,
                        text: 'Boat Picture added successfully.',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then((result) =>
                    {
                        router.push('/boat/list')
                    });
                }
                else
                {
                    this.handleResponse('Something went wrong!', 'error');
                }
            });
        },
        submitDocument(event)
        {
            store.set('accounts/loader', true);
            boatServices.submitBoatDocument(event).then(res =>
            {
                if (res.statusCode == 200)
                {
                    this.handleResponse('Boat Document added successfully.', 'success');
                    store.set('accounts/loader', false);
                }
                else
                {
                    this.handleResponse('Something went wrong!', 'error');
                }
            });
        },
        handleResponse(text, type, step)
        {
            this.$swal({
                title: ``,
                text: text,
                confirmButtonColor: darkMode.getTextColor(),
                customClass: darkMode.getBackgroundColor(),
                icon: type,
            }).then((result) =>
            {
                if (step)
                {
                    this.step = step;
                }
            });
        }
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
            icons: {
                mdiPlus,
                mdipluscircle
            },
        }

    }
}

</script>

<style lang="scss" scoped>
.button-row {
    display: flex;
    flex-wrap: wrap;
}

.button {
    flex-basis: 100%;
}

.text-blue {
    color: #006fcf;
}

.text-black {
    color: #000;
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.green-text {
    color: #00B2A9
}

.license-color {
    color: #00B2A9
}

.boat-tab {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.bg {
    background: #f1f1f1;
}

.diableDiv {
    pointer-events: none;
    opacity: 0.4;
}
</style>
