import axios from './axiosInit'

const submitBoat = async data =>
{
    const resp = await axios.post(`boat/onboarding/create-boat`, data)
    return resp
}

const updateBoat = async data =>
{
    const resp = await axios.post(`boat/onboarding/update-boat`, data)
    return resp
}

const updateBoatOwner = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-owner`, data)
    return resp.data
}

const submitBoatOwner = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-owner`, data)
    return resp.data
}

const submitBoatDocument = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-documents`, data)
    return resp.data
}


const submitPackage = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-packages`, data)
    return resp.data
}

const submitPricing = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-pricing`, data)
    return resp
}

const updatePackage = async data =>
{
    const resp = await axios.post(`boat/onboarding/update-package`, data)
    return resp.data
}



const submitBoatCondition = async data =>
{
    const resp = await axios.post(`boat/onboarding/boat-condition`, data)
    return resp.data
}


const getBoatData = async (id, endpoint) =>
{
    try
    {
        const result = await axios.get('boat/' + endpoint + '/list?id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getApplicationList = async (options, searchValue, id, checkStatus, slug) =>
{
    try
    {
        if (!slug)
        {
            slug = 'onboarding';
        }

        let url = 'boat/' + slug + '/list?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage;

        if (searchValue)
        {
            url += '&search=' + searchValue
        }
        if (checkStatus)
        {
            url += '&status=' + checkStatus
        }
        if (id)
        {
            url += '&owner_id=' + id
        }

        const result = await axios.get(url)
        return result

    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getOwnerInformation = async id =>
{
    try
    {
        const result = await axios.get('users/list?id=' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getUserApplication = async id =>
{
    try
    {
        const result = await axios.get('userApplication/' + id)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateApplicationStatus = async (data, url) =>
{

    try
    {
        const result = await axios.post(url, data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const submitBoatGallery = async (data) =>
{

    try
    {
        const result = await axios.post('boat/onboarding/boat-pictures', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getBoatDocument = async (data, slug) =>
{

    try
    {
        if (!slug)
        {
            slug = 'onboarding';
        }

        const result = await axios.get('boat/'+ slug +'/get-boat-documents?boat_id=' + data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getBoatPicture = async (data, slug) =>
{

    try
    {
        if (!slug)
        {
            slug = 'onboarding';
        }

        const result = await axios.get('boat/'+ slug +'/get-boat-pictures?boat_id=' + data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removeBoatPicture = async (data) =>
{
    try
    {
        const result = await axios.post('boat/onboarding/remove-boat-picture', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removeBoatDocuments = async (data) =>
{
    try
    {
        const result = await axios.post('boat/onboarding/remove-boat-document', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const removePackage = async (data) =>
{
    try
    {
        const result = await axios.post('boat/onboarding/remove-boat-package', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getAllOwners = async (data) =>
{
    try
    {
        const result = await axios.get('boat/onboarding/remove-boat-package', data)
        return result
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const updateStatus = async (data, slug) =>
{
    try
    {
        if (!slug)
        {
            slug = 'onboarding';
        }
        const result = await axios.post('boat/' + slug + '/active-boat', data)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}







export default {
    submitBoat,
    updateBoat,
    getOwnerInformation,
    submitBoatOwner,
    updateBoatOwner,
    submitBoatDocument,
    submitPackage,
    updatePackage,
    getBoatData,
    submitBoatCondition,
    getApplicationList,
    updateApplicationStatus,
    getUserApplication,
    submitPricing,
    submitBoatGallery,
    getBoatDocument,
    getBoatPicture,
    removeBoatPicture,
    removeBoatDocuments,
    removePackage,
    getAllOwners,
    updateStatus

}
