<template>
    <div>
        <v-form class="multi-col-validation"
                lazy-validation
                ref="generalForm"
                v-model="fieldIsFilled">
            <v-row class="mt-0 pa-3">
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Name') }}
                    </p>
                    <v-text-field :placeholder="$t('Boat Name')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.boat_name"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Name Ar') }}
                    </p>
                    <v-text-field :placeholder="$t('Boat Name Ar')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view"
                                  class="text-fields write-ar"
                                  v-model="myboat.boat_name_ar"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Category') }}
                    </p>
                    <v-select :items="categories"
                              :placeholder="$t('Category')"
                              variant="underlined"
                              item-text="name"
                              item-value="id"
                              :disabled="view"
                              class="text-fields"
                              :rules="[genericRules.required]"
                              @change="selectCategory"
                              v-model="myboat.category_id"></v-select>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Brand') }}
                    </p>
                    <v-text-field :placeholder="$t('Boat Brand')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view || edit"
                                  class="text-fields"
                                  v-model="myboat.boat_brand"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('City') }}
                    </p>
                    <v-select :items="cities"
                              :placeholder="$t('City')"
                              variant="underlined"
                              item-text="name"
                              :disabled="view"
                              item-value="id"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              @change="getMarinaByCityId(myboat.city_id)"
                              v-model="myboat.city_id"></v-select>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Marina') }}
                    </p>
                    <v-select :items="marinas"
                              :placeholder="$t('Marina')"
                              variant="underlined"
                              item-text="name"
                              :disabled="view"
                              :readonly="!myboat.city_id"
                              @change="onMarinaChange(myboat.marina_id)"
                              item-value="id"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              v-model="myboat.marina_id"></v-select>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Model') }}
                    </p>
                    <v-text-field :placeholder="$t('Model')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  :disabled="view || edit"
                                  v-model="myboat.boat_modal"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Engine Brand/ Model') }}
                    </p>
                    <v-text-field :placeholder="$t('Engine Brand')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view || edit"
                                  class="text-fields"
                                  v-model="myboat.engine_model">
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Year') }}
                    </p>
                    <v-select :items="yearList"
                              :placeholder="$t('Select Year')"
                              variant="underlined"
                              :rules="[genericRules.required]"
                              :disabled="view || edit"
                              class="text-fields"
                              v-model="myboat.year"></v-select>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Location') }}
                    </p>
                    <v-text-field :placeholder="$t('Location')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.location"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Latitude') }}
                    </p>
                    <v-text-field :placeholder="$t('Add Latitude')"
                                  variant="underlined"
                                  :rules="[genericRules.required, genericRules.numbers]"
                                  class="text-fields"
                                  v-mask="'##.######'"
                                  v-model="myboat.latitude"></v-text-field>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Longitude') }}
                    </p>
                    <v-text-field :placeholder="$t('Add Longitude')"
                                  variant="underlined"
                                  :rules="[genericRules.required, genericRules.numbers]"
                                  class="text-fields"
                                  v-mask="'##.######'"
                                  :disabled="view"
                                  v-model="myboat.longitude"></v-text-field>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Service & Maintenance Time') }}
                    </p>
                    <v-select :items="time"
                              :placeholder="$t('Select Time')"
                              variant="underlined"
                              :disabled="view"
                              :rules="[genericRules.required]"
                              item-text="name"
                              item-value="id"
                              class="text-fields"
                              v-model="myboat.maintenance_time"></v-select>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12"
                       v-if="!ownerRole">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Owner') }}
                    </p>
                    <v-autocomplete v-if="boatType != 'pre'"
                                    :items="boatOwners"
                                    :placeholder="$t('Select Boat Owner')"
                                    variant="underlined"
                                    item-text="first_name"
                                    item-value="id"
                                    :rules="[genericRules.required]"
                                    @change="selectBoatManager(myboat.owner_id)"
                                    :disabled="view"
                                    class="text-fields"
                                    v-model="myboat.owner_id"></v-autocomplete>
                    <v-text-field v-if="boatType == 'pre'"
                                  :placeholder="$t('Select Boat Owner')"
                                  variant="underlined"
                                  class="text-fields"
                                  :readonly="true"
                                  :disabled="view"
                                  v-model="preBoatOwner"></v-text-field>
                </v-col>

                <v-col v-if="boatType != 'pre'"
                       cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Captain') }}
                    </p>
                    <v-autocomplete :items="boatCaptains"
                                    :placeholder="$t('Select Boat Captain')"
                                    variant="underlined"
                                    item-text="first_name"
                                    :readonly="!myboat.owner_id"
                                    item-value="id"
                                    :disabled="view"
                                    class="text-fields"
                                    v-model="myboat.captain_id"></v-autocomplete>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12"
                       v-if="showLengthField || myboat.boat_length != 0">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Boat Length') }}
                    </p>
                    <v-select :items="boatLengthValues"
                              :placeholder="$t('Boat Length')"
                              variant="underlined"
                              :disabled="view"
                              suffix="ft"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              v-model="myboat.boat_length"></v-select>
                </v-col>

                <v-col cols="12"
                       md="6"
                       lg="12"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('General Boat Description') }}
                    </p>
                    <v-textarea solo
                                auto-grow
                                outlined
                                class="mt-2"
                                v-model="myboat.description"
                                :disabled="view"
                                :label="$t('Enter General Boat Description')">
                    </v-textarea>
                </v-col>
                <v-col cols="12"
                       md="6"
                       lg="12"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('General Boat Description Ar') }}
                    </p>
                    <v-textarea solo
                                auto-grow
                                outlined
                                class="mt-2 write-ar"
                                v-model="myboat.description_ar"
                                :disabled="view"
                                :label="$t('Enter General Boat Description Ar')">
                    </v-textarea>
                </v-col>

            </v-row>
        </v-form>
        <div class="mt-5">
            <v-btn color="primary"
                   class="text-lower owner-info-btn border-card"
                   v-if="!view"
                   @click="createBoatInfo"
                   :disabled="!fieldIsFilled">
                {{ $t('Next') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import router from '@/router';
import sharedServices from '@/services/shared-services';
import userServices from '@/services/user-services';
import store from '@/store';

export function latlngMask(value)
{
    const month = [
        /[0-9]/, value.length === '1' ? /[0-9]/ : /[]/, value.length === '2' ? /[0-9]/ : /[0-2]/
    ];
    const year = [/[0-9]/, /[0-9]/];
    return value.length > 2 ? [...month, '-', ...year] : month;
}

export default {
    props: {
        boatInformation: {
            default()
            {
                return {}
            },
            type: Object
        },
    },
    components: {
    },
    data: () => ({
        latlngMask,
        generalForm: null,
        fieldIsFilled: false,
        view: false,
        lengthRules: [
            v => !!v || "This field is required"
        ],
        showLengthField: true,
        genericRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
        },
        exampleBoatRules: [
            v => !!v || "This field is required",
            v => (v && parseInt(v) >= 15) || "Boat length must be between 14 - 48",
            v => (v && parseInt(v) <= 48) || "Boat length must be between 14 - 48",
        ],
        exampleYatchRules: [
            v => !!v || "This field is required",
            v => (v && parseInt(v) >= 36) || "Boat length must be between 36 - 200",
            v => (v && parseInt(v) <= 200) || "Boat length must be between 36 - 200",
        ],

        cities: [],
        marinas: [],
        countries: [],
        type: 'Add',
        edit: false,
        time: [{
            name: '1 Hour',
            id: '01:00:00'
        },
        {
            name: '2 Hours',
            id: '02:00:00'
        },
        {
            name: '3 Hours',
            id: '03:00:00'
        },
        {
            name: '4 Hours',
            id: '04:00:00'
        },
        {
            name: '5 Hours',
            id: '05:00:00'
        },
        {
            name: '6 Hours',
            id: '06:00:00'
        },
        {
            name: '7 Hours',
            id: '07:00:00'
        },
        {
            name: '8 Hours',
            id: '08:00:00'
        },
        {
            name: '8 Hours',
            id: '08:00:00'
        },
        {
            name: '9 Hours',
            id: '09:00:00'
        },
        {
            name: '10 Hours',
            id: '10:00:00'
        },
        {
            name: '11 Hours',
            id: '11:00:00'
        },
        {
            name: '12 Hours',
            id: '12:00:00'
        }
        ],
        boatOwners: [],
        boatCaptains: [],
        myboat: {
            boat_name: '',
            boat_name_ar: '',
            boat_length: '',
            cancellation_time: '',
            owner_id: null,
            captain_id: '',
            category_id: '',
            boat_brand: '',
            city_id: '',
            marina_id: '',
            model: '',
            engine_model: '',
            year: '',
            location: null,
            maintenance_time: '',
            latitude: '',
            longitude: '',
            description: '',
            description_ar: '',
            status: ''
        },
        boatType: '',
        ownerRole: false,
        categories: [],
        yearList: [],
        boatLengthValues: [],
        latLngInput: '',
        preBoatOwner: ''
    }),

    mounted()
    {
        this.getCities();
        this.getCountries();
        this.getCategories();
        this.getBoatOwners();
        this.getYear();

        if (router.currentRoute.params.type == 'view')
        {
            this.view = true;
        }

        if (router.currentRoute.params.type == 'update')
        {
            this.edit = true;
        }

        if (router.currentRoute.params.type == 'pre')
        {
            this.edit = false;
            this.view = true;
            this.boatType = 'pre';
        }

        let userData = store.get('accounts/userData');
        if (userData.role == 'boat_owner')
        {
            this.ownerRole = true;
            this.myboat.owner_id = userData.id;
            store.set('boatRegistrations/owner_id', this.myboat.owner_id);
        }
    },
    methods: {
        formatLatitude()
        {
            const cleanedInput = this.myboat.latitude.replace('..', '.');
            this.myboat.latitude = cleanedInput
        },
    



    generateNumbersArray(first, second)
    {
        this.boatLengthValues = [];
        for (let i = first; i <= second; i++)
        {
            this.boatLengthValues.push(i);
        }
    },
    createBoatInfo()
    {
        const isFormValid = this.$refs.generalForm.validate()
        if (!isFormValid) return;

        if (router.currentRoute.params.id)
        {
            this.myboat['id'] = router.currentRoute.params.id;
            // this.myboat.status = '2';
            this.$emit('updateBoatInfo', this.myboat);
        }
        else
        {
            this.myboat.status = '0';
            this.$emit('saveBoatInfo', this.myboat);
        }


    },
    validateInput(event)
    {
        // Get the key code of the pressed key
        const keyCode = event.keyCode || event.which;

        // Allow alphabets (a-z, A-Z), spaces (32), and backspace (8)
        const validKeyCodes = [32, 8];
        for (let i = 65; i <= 90; i++)
        {
            validKeyCodes.push(i);
        }
        for (let i = 97; i <= 122; i++)
        {
            validKeyCodes.push(i);
        }

        // If the pressed key is not in the valid key codes, prevent it
        if (!validKeyCodes.includes(keyCode))
        {
            event.preventDefault();
        }
    },
    getYear()
    {
        var max = new Date().getFullYear()
        var min = max - 53

        for (var i = max; i >= min; i--)
        {
            this.yearList.push(i)
        }
    },
    getBoatOwners()
    {
        let data = {
            role: 'boat_owner'
        }
        userServices.getOwnerManagerList(data).then(resp =>
        {
            if (resp.statusCode == 200)
            {
                this.boatOwners = resp.data.users
            }
        })
    },
    selectCategory(value)
    {
        this.categories.filter(element =>
        {
            if (element.id == value)
            {
                this.showLengthField = true;
                if (element.name.toLowerCase().trim() == 'boat')
                {
                    this.generateNumbersArray(14, 48);
                }
                else if (element.name.toLowerCase().trim() == 'yacht')
                {
                    this.generateNumbersArray(36, 200);
                }
                else
                {
                    this.showLengthField = false;
                    this.lengthRules = [
                        v => !!v || "This field is required"
                    ];
                    this.myboat.boat_length = 0;
                }

                this.myboat.slot_time = element.min_time_slot;

                store.set('boatRegistrations/slotTime', this.myboat.slot_time);
                store.set('boatRegistrations/bookingType', element.category_booking_type);
            }
        })

    },
    selectBoatManager(id)
    {
        store.set('boatRegistrations/owner_id', id);
        sharedServices.getCaptainByOwnerId(id).then(resp =>
        {
            console.log('captainsssssssssss', resp)
            this.boatCaptains = resp.data.users;
        })
    },
    getCities()
    {
        sharedServices.getCities().then(resp =>
        {
            this.cities = resp.data.cities;
        })
    },
    getMarinaByCityId(id)
    {
        sharedServices.getMarinaByCityId(id).then(resp =>
        {
            this.marinas = resp.data.marinas;
        })
    },
    onMarinaChange(id)
    {
        let cityObject = this.marinas.find(x => x.id === id);

        if (cityObject)
        {
            this.myboat.latitude = cityObject.latitude == null ? 0 : cityObject.latitude;
            this.myboat.longitude = cityObject.longitude == null ? 0 : cityObject.longitude;

            this.latLngInput = this.myboat.latitude + ',' + this.myboat.longitude;
        }
    },
    getCountries()
    {
        sharedServices.getCountries().then(resp =>
        {
            this.countries = resp.data.countries;
        })
    },
    getCategories()
    {
        sharedServices.getCategories().then(resp =>
        {
            this.categories = resp.data.categories;
        })
    }
},
watch: {
    boatInformation: {
        immediate: true,
            handler(newVal)
        {
            this.localObject = Object.assign({}, newVal);

            if (this.localObject)
            {
                this.myboat = this.localObject;
                this.latLngInput = this.localObject.latLngInput;
                this.myboat.boat_length = this.localObject.boat_length;

                if (this.boatType == 'pre')
                {
                    this.preBoatOwner = this.localObject.owner_name;
                }

                store.set('boatRegistrations/owner_id', this.myboat.owner_id);

                setTimeout(() =>
                {
                    this.getMarinaByCityId(this.myboat.city_id);
                    this.selectCategory(parseInt(this.myboat?.category_id));
                    this.selectBoatManager(this.myboat.owner_id);
                }, 300);

                if (router.currentRoute.params.type == 'update')
                {
                    this.edit = true;
                }
            }
        }
    }
},
setup()
{
    const { t } = useUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
        // i18n
        t,

        // ACL
        canViewVerticalNavMenuLink
    }

}

}
</script>

<style lang="scss" scoped>
.text-black {
    color: #000;
}


.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

.text-fields {
    margin: -4px 0px 0px;
}</style>
