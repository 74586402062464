<template>
    <div>
        <v-form class="multi-col-validation"
                lazy-validation
                ref="ownerForm"
                v-model="boatOwnerField">
            <v-row class="mt-0">
                <v-col cols="12"
                       md="6"
                       lg="4"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Owner Name') }}
                    </p>
                    <v-text-field :placeholder="$t('Owner Name')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  @keydown="validateInput"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.owner_name"></v-text-field>
                </v-col>
                <!-- <v-col cols="12"
                               md="6"
                               sm="12">
                          
                        </v-col> -->
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Owner Type') }}
                    </p>
                    <!-- <v-text-field :placeholder="$t('Owner Type')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.type"></v-text-field> -->

                    <v-select :items="ownerTypes"
                              :placeholder="$t('Owner Type')"
                              variant="underlined"
                              hide-details
                              :disabled="view"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              v-model="myboat.type"></v-select>
                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('ID No') }}
                    </p>
                    <v-text-field :placeholder="$t('ID No')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.id_no"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Certificate of Owner Registration') }}
                    </p>
                    <v-text-field :placeholder="$t('Certificate of Owner Registration')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.certification"></v-text-field>

                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Country') }}
                    </p>
                    <v-select :items="countries"
                              :placeholder="$t('Country')"
                              variant="underlined"
                              hide-details
                              :disabled="view"
                              item-text="name"
                              item-value="id"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              v-model="myboat.country_id"></v-select>
                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Unit') }}
                    </p>
                    <v-text-field :placeholder="$t('Unit')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.unit"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Street') }}
                    </p>
                    <v-text-field :placeholder="$t('Street')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.street"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('District') }}
                    </p>
                    <v-text-field :placeholder="$t('District')"
                                  variant="underlined"
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  @keydown="validateInput"
                                  class="text-fields"
                                  v-model="myboat.district"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Structure No') }}
                    </p>
                    <v-text-field :placeholder="$t('Structure No')"
                                  variant="underlined"
                                  hide-details
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.structure_number"></v-text-field>
                </v-col>



                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('City') }}
                    </p>
                    <v-select :items="cities"
                              :placeholder="$t('City')"
                              variant="underlined"
                              item-text="name"
                              item-value="id"
                              :disabled="view"
                              :rules="[genericRules.required]"
                              class="text-fields"
                              v-model="myboat.owner_city"></v-select>

                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Phone') }}
                    </p>

                    <phone-number-input v-model="myboat.bank_phone"
                                        @input="handlePhone"
                                        :default-country-code="'SA'"
                                        v-bind="$attrs"
                                        style="border: none;"
                                        :color="'#00b2a9'"
                                        :disabled="view"
                                        :minLength="8"
                                        :maxlength="11"
                                        class="code-select" />
                    <span v-if="isPhoneLength"
                          style="color: red;font-size: 11px;">{{ $t('Please enter valid Mobile Number') }}</span>

                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Email ID') }}
                    </p>
                    <v-text-field :placeholder="$t('Email')"
                                  variant="underlined"
                                  :rules="[genericRules.required, genericRules.email]"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.bank_email"></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mt-5">
                <v-col cols="12"
                       md="12"
                       sm="12">
                    <div class="d-flex align-center">
                        <span class="text-fields font-weight-semibold text-base text-green">
                            {{ $t('Account Information') }}
                        </span>
                    </div>
                </v-col>

                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Bank Name') }}
                    </p>
                    <v-text-field :placeholder="$t('Bank Name')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.bank_name"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('Branch') }}
                    </p>
                    <v-text-field :placeholder="$t('Branch')"
                                  variant="underlined"
                                  :rules="[genericRules.required]"
                                  :disabled="view"
                                  class="text-fields"
                                  v-model="myboat.branch"></v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="3"
                       md="6"
                       sm="12">
                    <p class="font-weight-semibold mb-0 text-black">
                        {{ $t('IBAN') }}
                    </p>
                    <v-text-field :placeholder="$t('IBAN')"
                                  variant="underlined"
                                  :disabled="view"
                                  :rules="[genericRules.required]"
                                  class="text-fields"
                                  v-model="myboat.iban"></v-text-field>
                </v-col>

            </v-row>
        </v-form>
        <div class="mt-5"
             v-if="!view">
            <v-btn @click="back('info')"
                   class="mr-4 text-lower text-color text-green"
                   outlined
                   color="#00B2A9">
                {{ $t('Back') }}
            </v-btn>
            <v-btn color="primary"
                   class="text-lower owner-info-btn border-card"
                   :disabled="!boatOwnerField"
                   @click="boatOwner">
                {{ $t('Next') }}
            </v-btn>
        </div>


    </div>
</template>

<script>

import { useUtils as useAclUtils } from '@core/libs/acl';
import { useUtils } from '@core/libs/i18n';

import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import router from '@/router';
import sharedServices from '@/services/shared-services';
import
{
    mdiPlus, mdipluscircle
} from '@mdi/js';
import store from '@/store';
export default {
    props: {
        ownerInformation: {
            default()
            {
                return {}
            },
            type: Object
        },
    },
    components: {
        PhoneNumberInput
    },
    data: () => ({
        ownerForm: null,
        ownerTypes: ['Individual', 'Corporate'],
        boatOwnerField: false,
        isPhoneLength: false,
        view: false,
        genericRules: {
            required: v => !!v || 'Field is required',
            numbers: v => /^[+0-9,.]*$/.test(v) || 'Only numbers are allowed',
            characters: v => /^[a-z A-Z]*$/.test(v) || 'Only characters are allowed',
            email: v => /.+@.+/.test(v) || 'E-mail must be valid',
        },
        cities: [],
        countries: [],
        myboat: {
            owner_name: '',
            unit: '',
            type: '',
            street: '',
            id_no: '',
            district: '',
            certification: '',
            owner_city: '',
            country_id: '',
            bank_name: '',
            branch: '',
            bank_phone: null,
            bank_email: '',
            iban: '',
            owner_id: null,
            bank_iban: null,
        },
    }),
    mounted()
    {
        const userData = JSON.parse(localStorage.getItem('userData'));
        // console.log(userData);
        if (userData.role == 'boat_owner')
        {
            this.ownerRole = false;
            this.myboat.boatOwnerId = userData.id;
        }
        this.getCities();
        this.getCountries();
        if (router.currentRoute.params.type == 'view')
        {
            this.view = true;
        }
        if (router.currentRoute.params.id)
        {
            store.set('boatRegistrations/boatCreationId', router.currentRoute.params.id);
        }
    },
    methods: {
        back(value)
        {
            this.$emit('backToPage', value);
        },
        boatOwner()
        {
            const isFormValid = this.$refs.ownerForm.validate()
            if (!isFormValid) return
            if (router.currentRoute.params.id)
            {
                let obj = {
                    "boat_id": store.get('boatRegistrations/boatCreationId'),
                    "owner_name": this.myboat.owner_name,
                    "owner_type": this.myboat.type,
                    "certificate": this.myboat.certification,
                    "information_id": this.myboat.id_no,
                    "country_id": this.myboat.country_id,
                    "unit": this.myboat.unit,
                    "address": this.myboat.street,
                    "district": this.myboat.district,
                    "structure_number": this.myboat.structure_number,
                    "city": this.myboat.owner_city,
                    "phone": this.myboat.bank_phone,
                    "email": this.myboat.bank_email,
                    "bank_name": this.myboat.bank_name,
                    "bank_branch": this.myboat.branch,
                    "bank_iban": this.myboat.iban
                }
                this.$emit('saveBoatOwner', obj);
            }
            else
            {
                let obj = {
                    "boat_id": store.get('boatRegistrations/boatCreationId'),
                    "owner_name": this.myboat.owner_name,
                    "owner_type": this.myboat.type,
                    "certificate": this.myboat.certification,
                    "information_id": this.myboat.id_no,
                    "country_id": this.myboat.country_id,
                    "unit": this.myboat.unit,
                    "address": this.myboat.street,
                    "district": this.myboat.district,
                    "structure_number": this.myboat.structure_number,
                    "city": this.myboat.owner_city,
                    "phone": this.myboat.bank_phone,
                    "email": this.myboat.bank_email,
                    "bank_name": this.myboat.bank_name,
                    "bank_branch": this.myboat.branch,
                    "bank_iban": this.myboat.iban
                }
                this.$emit('saveBoatOwner', obj);
            }
        },

        validateInput(event)
        {
            // Get the key code of the pressed key
            const keyCode = event.keyCode || event.which;

            // Allow alphabets (a-z, A-Z), spaces (32), and backspace (8)
            const validKeyCodes = [32, 8];
            for (let i = 65; i <= 90; i++)
            {
                validKeyCodes.push(i);
            }
            for (let i = 97; i <= 122; i++)
            {
                validKeyCodes.push(i);
            }

            // If the pressed key is not in the valid key codes, prevent it
            if (!validKeyCodes.includes(keyCode))
            {
                event.preventDefault();
            }
        },

        handlePhone()
        {
            if (this.myboat.bank_phone.length < 8)
            {
                // console.log('phone invalid')
                // this.myboat.bank_phone = '';
                this.isPhoneLength = true;
                return;
            }
            else
            {
                this.isPhoneLength = false;
            }
        },
        getCities()
        {
            sharedServices.getCities().then(resp =>
            {
                // console.log(resp)
                this.cities = resp.data.cities;
            })
        },
        getCountries()
        {
            sharedServices.getCountries().then(resp =>
            {
                // console.log(resp)
                this.countries = resp.data.countries;
            })
        }
    },
    watch: {
        // Watch for changes in the prop object and assign it to the local variable
        ownerInformation: {
            immediate: true,
            handler(newVal)
            {
                this.localObject = Object.assign({}, newVal);
                if (this.localObject)
                {
                    this.ownerId = this.localObject.ownerId;
                    this.myboat.owner_name = this.localObject.owner_name;
                    this.myboat.type = this.localObject.type;
                    this.myboat.certification = this.localObject.certification;
                    this.myboat.id_no = this.localObject.id_no;
                    this.myboat.country_id = +this.localObject.country_id;
                    this.myboat.unit = this.localObject.unit;
                    this.myboat.street = this.localObject.street;
                    this.myboat.district = this.localObject.district;
                    this.myboat.structure_number = this.localObject.structure_number;
                    this.myboat.owner_city = +this.localObject.owner_city;

                    this.myboat.bank_phone = this.localObject.bank_phone;
                    this.myboat.bank_email = this.localObject.bank_email;
                    this.myboat.bank_name = this.localObject.bank_name;
                    this.myboat.branch = this.localObject.branch;
                    this.myboat.iban = this.localObject.iban;

                }
            }
        }
    },
    setup()
    {
        const { t } = useUtils()
        const { canViewVerticalNavMenuLink } = useAclUtils()

        return {
            // i18n
            t,

            // ACL
            canViewVerticalNavMenuLink,
            icons: {
                mdiPlus,
                mdipluscircle
            },
        }

    }
}

</script>

<style lang="scss" scoped>
// ::v-deep .swal2-popup {
//     display: none;
//     position: relative;
//     box-sizing: border-box;
//     grid-template-columns: minmax(0, 100%);
//     width: 32em;
//     max-width: 100%;
//     padding: 0 0 1.25em;
//     border: none;
//     border-radius: 5px;
//     background: #fff;
//     color: #545454;
//     font-family: inherit;
//     /* font-size: 1rem; */
//     background: red !important;
//     /* Repplace with your desired background color */
// }


.button-row {
    display: flex;
    flex-wrap: wrap;
}

.button {
    flex-basis: 100%;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 4px;
}

.only-divider .v-divider {
    border-width: 1px !important;
}

.text-blue {
    color: #006fcf;
}

.file-box {
    background: #FFFFFF;
    width: 36px;
    height: 36px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    text-align: center;
}

.text-black {
    color: #000;
}

.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.green-text {
    color: #00B2A9
}

.plus-img {
    margin: auto;
    /* height: 101px; */
    /* margin: 0 auto; */
    display: flex;
    /* justify-content: center; */
    height: 50px;
    margin-top: 46px;
}

.secure-img {
    background: #FFFFFF;
    width: 43px;
    height: 41px;
    padding-top: 33px;
    border-radius: 79px;
    margin: 0 auto;
    position: absolute;
    right: 9px;
    top: 5px;
}

.attach-file {
    height: 20px;
    margin: 4px;
}

.license-color {
    color: #00B2A9
}

.document {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.owner-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.packages {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.boat-condition {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
}

.cross {
    width: 24px;
    height: 22px;
    position: absolute;
    right: -12px;
}

.upload-document {
    color: rgb(255, 255, 255);
    align-items: center;
    background: #F9F9F9;
    backdrop-filter: blur(7px);
    border-radius: 10px;
    padding: 20px 0px;
    height: 150px;
    width: 165px;
    border: 1px dashed #B1B1B1;
    // height:154px;
}

.package-item {
    border-radius: 5px;
    border: 1px solid #DADADA;
    border-radius: 12px;
    padding: 12px 15px;
}

.upload-img {
    background-color: #00B2A9 !important;
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 82px 38px 0px;
}


.dummy-img {
    /* border: 3px solid; */
    padding: 6px;
    border-radius: 10px;
    /* color: white; */
    text-align: center;
    width: 90px;
    font-size: 11px;
    margin: 35px 38px 0px;
}

.bg-white {
    background: white;
}

.v-expansion-panel {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

.bg {
    background: #f1f1f1;
}

.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
    box-shadow: 0px 0px 28px 2px #0000001A !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

.imge {
    height: 20px;
    width: 22px;
    margin: 0px 69px -49px;
}

.category-text {
    color: white;
    position: absolute;
    bottom: 20px;
    left: 24px;
    right: 24px;
}

.category-icon {
    position: absolute;
    top: 7px;
    right: 12px;
    background: white;
    width: 34px;
    height: 33px;
    padding: 6px;
    border-radius: 30px;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}

.document-icon {
    position: absolute;
    top: 50px;
    right: 25px;
    width: 88px;
    height: 56px;
}



.diableDiv {
    pointer-events: none;
    opacity: 0.4;
}

.text-fields {
    margin: -4px 0px 0px;
}

.text-color {
    color: #00B2A9;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}

::v-deep .code-select {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    caret-color: none !important;
}

::v-deep .country-selector__label[data-v-46e105de] {
    position: absolute;
    top: 3px;
    cursor: pointer;
    display: none !important;
    left: 11px;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
    opacity: 0;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    font-size: 11px;
    color: #747474;
}

::v-deep .input-tel.is-focused {
    border-color: none !important;
    caret-color: none !important;
}



::v-deep .input-tel__input[data-v-e59be3b4] {
    cursor: pointer;
    background-color: #fff;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    position: relative;
    width: 100%;
    padding: 0 12px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
    border-top: none !important;
    border-right: none !important;
    margin-left: -1px;
    height: 40px;
    min-height: 40px;
    border-left: none !important;
    border-color: none !important;
}



::v-deep .input-tel.has-hint .input-tel__label[data-v-e59be3b4],
.input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}


::v-deep .input-tel.has-value .input-tel__label[data-v-e59be3b4] {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    font-size: 11px;
    display: none !important;
}

::v-deep .country-selector__input[data-v-46e105de] {
    cursor: pointer;
    background-color: #fff;
    position: relative;
    width: 100%;
    height: 40px;
    min-height: 40px;
    padding-right: 22px;
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 13px;
    border-top: none !important;
    z-index: 0;
    padding-left: 8px;
    color: #000;
    border-left: none !important;
    border-color: none !important;
}
</style>