import axios from './axiosInit'

const getCities = async () =>
{
    try
    {
        const result = await axios.get('cities/list')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getMarinaByCityId = async id =>
{
    try
    {
        const result = await axios.get(`marinas/list?city_id=${id}`)

        return result.data
    } catch (e)
    {
        console.log(e)

        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCaptainByOwnerId = async id =>
{
    try
    {
        const result = await axios.get('users/list?role=boat_captain&owner_id=' + id)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCountries = async () =>
{
    try
    {
        const result = await axios.get('countries/list')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getRates = async () =>
{
    try
    {
        const result = await axios.get('rates')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getCategories = async () =>
{
    try
    {
        const result = await axios.get('categories/list')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
    getCities,
    getCountries,
    getRates,
    getCategories,
    getMarinaByCityId,
    getCaptainByOwnerId
}
